import './react-pro-sidebar.css';
import Authorize from 'components/common/authorize';
import React, { useRef } from 'react';
import UserProfile from 'components/layout/user-profile';
import moment from 'moment';
import { APPS, PERMISSIONS } from 'components/common/constants';
import { Link } from "react-router-dom";
import { Sidebar, Menu, MenuItem, useProSidebar, } from 'react-pro-sidebar';
import { getAuth } from "firebase/auth";

export default function SideMenu(props) {

    const ref = useRef(null);
    
    const firebaseUser = getAuth().currentUser;
    const email = firebaseUser?.email;
    const { collapseSidebar, collapsed } = useProSidebar();

    return (

        <div ref={ref} style={{ display: 'flex', height: '100%', position: 'absolute', zIndex: 9999 }}
            onMouseEnter={() => collapseSidebar(false)}
            onMouseLeave={() => collapseSidebar(true)}
        >
            <Sidebar
                width={'240px'}
                style={{ height: '100vh' }}
                defaultCollapsed={true}
                collapsedWidth='80px'
                backgroundColor='#2f4050'
            >
                <div style={{ minHeight: '195px', marginBottom: '20px' }} >
                    {props.userRoles && props.user && email &&
                        <UserProfile container={ref} user={props.user} email={email} userRoles={props.userRoles} collapsed={collapsed} />
                    }
                </div>
                <Menu iconShape="circle" closeOnClick={true}>
                    {/* <MenuItem active={props.location.pathname.startsWith(`/${props.handleApp}/home`)}
                        icon={<i className="fas fa-home fa-lg"></i>}
                        component={<Link replace onClick={() => collapseSidebar(true)} to={`/${props.handleApp}/home`} />}
                    >HOME
                    </MenuItem> */}

                    <MenuItem active={props.location.pathname.startsWith(`/${props.handleApp}/dashboard`)}
                        icon={<i className="fas fa-tachometer-alt fa-lg"></i>}
                        component={<Link replace onClick={() => collapseSidebar(true)} to={`/${props.handleApp}/dashboard`} />}
                    >DASHBOARD
                    </MenuItem>

                    <Authorize perm={ PERMISSIONS.CUSTOMERS_VIEW }>
                        <MenuItem active={props.location.pathname.startsWith(`/${props.handleApp}/customers`)}
                            icon={<i className="fas fa-users fa-lg"></i>}
                            component={<Link  replace onClick={() => collapseSidebar(true)} to={`/${props.handleApp}/customers`} />}
                        >CUSTOMERS
                        </MenuItem>
                    </Authorize>

                    <Authorize appId={ APPS.ASSETS.id } perm={ PERMISSIONS.ASSETS_VIEW }>
                        <MenuItem active={props.location.pathname.startsWith(`/${props.handleApp}/assets`)}
                            icon={<i className="fas fa-truck fa-lg"></i>}
                            component={<Link replace onClick={() => collapseSidebar(true)} to={`/${props.handleApp}/assets`} />}        
                        >ASSETS
                        </MenuItem>
                    </Authorize>

                    <Authorize perm={ PERMISSIONS.SERVICE_REQUESTS_VIEW }>
                        <MenuItem active={props.location.pathname.startsWith(`/${props.handleApp}/service`)}
                            icon={<i className="fas fa-file-alt fa-lg"></i>}
                            component={<Link  replace onClick={() => collapseSidebar(true)} to={`/${props.handleApp}/service`} />}
                        >SERVICE
                        </MenuItem>
                    </Authorize>

                    <Authorize perm={ PERMISSIONS.INVENTORY_VIEW }>
                        <MenuItem active={props.location.pathname.startsWith(`/${props.handleApp}/inventory`)}
                            icon={<i className="fas fa-toolbox fa-lg"></i>}
                            component={<Link replace onClick={() => collapseSidebar(true)} to={`/${props.handleApp}/inventory`} />}
                        >INVENTORY
                        </MenuItem>
                    </Authorize>

                    <Authorize perm={ PERMISSIONS.VENDORS_VIEW }>
                        <MenuItem active={props.location.pathname.startsWith(`/${props.handleApp}/vendors`)}
                            icon={<i className="fas fa-store fa-lg"></i>}
                            component={<Link replace onClick={() => collapseSidebar(true)} to={`/${props.handleApp}/vendors`} />}
                        >VENDORS
                        </MenuItem>
                    </Authorize>
                    {/* <Authorize perm={ PERMISSIONS.PURCHASE_ORDERS_VIEW }>
                        <MenuItem active={props.location.pathname.startsWith(`/${props.handleApp}/vendors`)}
                            icon={<i className="fas fa-file-alt fa-lg"></i>}
                            component={<Link replace onClick={() => collapseSidebar(true)} to={`/${props.handleApp}/vendors`} />}
                        >PURCHASE ORDERS
                        </MenuItem>
                    </Authorize> */}


                    {/* <MenuItem
                        active={props.location.pathname.startsWith('/' + props.handleApp + '/purchase_orders')}
                        icon={<i className="fas fa-file-alt fa-lg"></i>}
                        component={<Link replace onClick={() => collapseSidebar(true)} to={'/' + props.handleApp + '/purchase_orders'} />}
                    >
                        PURCHASE ORDERS
                    </MenuItem> */}

                    <Authorize perm={ PERMISSIONS.SCHEDULE_VIEW }>
                        <MenuItem active={props.location.pathname.startsWith(`/${props.handleApp}/schedule`)}
                            icon={<i className="far fa-calendar-alt fa-lg"></i>}
                            component={<Link replace onClick={() => collapseSidebar(true)} to={`/${props.handleApp}/schedule/${moment().format('YYYY-MM-DD')}`} />}
                        >SCHEDULE
                        </MenuItem>
                    </Authorize>

                    <Authorize perm={ PERMISSIONS.EMPLOYEES_VIEW }>
                        <MenuItem active={props.location.pathname.startsWith(`/${props.handleApp}/employees`)}
                            icon={<i className="fas fa-id-badge fa-lg" aria-hidden="true"></i>}
                            component={<Link replace onClick={() => collapseSidebar(true)} to={`/${props.handleApp}/employees`} />}
                        >EMPLOYEES
                        </MenuItem>
                    </Authorize>

                    <div className="mb-5"></div>

                    <Authorize perm={ PERMISSIONS.ARCHIVES_VIEW }>
                        <MenuItem rootStyles={{ position: 'absolute', left: 0, bottom: 100 }}
                            active={props.location.pathname.startsWith(`/${props.handleApp}/archive`)}
                            icon={<i className="fas fa-archive"></i>}
                            component={<Link onClick={() => collapseSidebar(true)} to={`/${props.handleApp}/archive`} />}
                        >ARCHIVE
                        </MenuItem>
                    </Authorize>
                    
                    <MenuItem active={props.location.pathname.startsWith(`/${props.handleApp}/settings`)}
                        rootStyles={{ position: 'absolute', left: 0, bottom: 50 }}
                        icon={<i className="fas fa-cog"></i>}
                        component={<Link onClick={() => collapseSidebar(true)} to={`/${props.handleApp}/settings/company`} />}
                    >SETTINGS
                    </MenuItem>
                </Menu>
                <main style={{ position: 'absolute', left: 10, bottom: 5 }}>
                    <center>
                        {window.build}
                    </center>
                </main>
            </Sidebar>
        </div>
    );
}
