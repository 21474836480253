import * as toolbox from 'components/common/toolbox';
import React from 'react';
import firebase from 'firebase/compat/app';
import { APPS } from 'components/common/constants';
import { ModalAlert } from 'enspire-manager-framework';

const firestore = firebase.firestore();
var _ = require('lodash');
var moment = require('moment');

/* CONFIRMATION ----------------------------------------------------------------------------*/

export function confirmAdd(type, callback, { additional } = {}) {
	ModalAlert({
		title: 'Are you sure?',
		html: `Are you sure you want to add this ${type}? ` + ((additional) ? additional : ''),
		icon: 'warning',
		confirm_color: '#8FBC8B',
		confirm_text: 'Yes, add',
		show_cancel: true,
		callback_success: () => { callback(); }
	});
};
export function confirmRemove(type, callback, { additional } = {}) {
	ModalAlert({
		title: 'Are you sure?',
		html: `This ${type} will be removed upon saving. ` + ((additional) ? additional : ''),
		icon: 'warning',
		confirm_color: '#8FBC8B',
		confirm_text: 'Yes, remove',
		show_cancel: true,
		callback_success: () => { callback(); }
	});
};
export function confirmDelete(type, callback, { removedFrom, soft } = {} ) {
	ModalAlert({
		title: 'Are you sure?',
		html: `This ${type} ${soft} will be ${(soft)?'deleted':'<strong className="text-danger">permanently deleted'}` + (((removedFrom) ? ` and removed from existing ${removedFrom}` : '') + `</strong>`),
		icon: 'warning',
		confirm_color: '#8FBC8B',
		confirm_text: `Yes, ${(soft)?'':'permanently '} delete`,
		deny_text: 'No, take me back',
		show_deny: true,
		callback_success: () => { callback(); }
	});
};
export function confirmArchive(type, callback) {
	ModalAlert({
		title: 'Are you sure?',
		text: `This ${type} will be Archived and not appear in ${type} search! It can be restored or deleted permanently by visiting the Archive page.`,
		icon: 'warning',
		show_cancel: true,
		confirm_color: '#8FBC8B',
		confirm_text: 'Yes, archive',
		callback_success: () => { callback(); }
	});
};

/* DATABASE ----------------------------------------------------------------------------*/

export async function nextId(handle, batch, table, field, startingId) {

	batch.update(firestore.collection(handle).doc(table), { [field]: firebase.firestore.FieldValue.increment(1) });

	var doc = await firestore.collection(handle).doc(table).get();
	if (!doc.exists || !doc.data()[field]) {
		await firestore.collection(handle).doc(table).set({ [field]: startingId }, { merge: true });
	}
	return (doc.exists && doc.data()[field]) ? doc.data()[field]?.toString() : startingId.toString();
}
export async function getProfileName(handle, id) {
	if (!id) return '';
	var doc = await firestore.collection(`${handle}/profiles/profiles`).doc(id).get();
	return doc.data()?.displayName;
}

/* FORMS & VALIDATION ----------------------------------------------------------------------------*/

export function process_error(error, message) {

	if (error.toString().includes('code 401')) {
		window.toastr.warning(error, 'UNAUTHORIZED!');
	} else {
		window.toastr.error(error, message);
	}
}

/* MODIFICATION ----------------------------------------------------------------------------------*/

// this function utilizes each of the following three functions to perform a list link (e.g. Asset Search)
// 
export function listLink(history, path, position, value, optional, lastpos) {
	let location = modifyPath(path, position, value, lastpos);
	let segments = value.split("/").length;
	if (optional) location = modifyPathIfEmpty(location, position + segments, optional); // useful for tabs
	pushOrReplace(history, path, position, location);
}
export function modifyPath(path, position, value, lastpos) {  // modify path position with value. Ignore after lastpos
	let pathArray = path.split('/');
	if (position <= pathArray.length - 1) {
		if (value) {
			let valueArray = value.split('/');
			for (let x = 0; x < valueArray.length; x++) {
				pathArray[position + x] = valueArray[x];
			}
		}
	} else {
		pathArray.push(value);
	}
	if (lastpos) pathArray = pathArray.slice(0, lastpos + 1);
	return pathArray.join('/');
}
export function modifyPathIfEmpty(path, position, value, lastpos) { // add value at path position, if that position is empty
	// This is designed to keep certain tabs open
	let pathArray = path.split('/');
	if (position > pathArray.length - 1) {
		let valueArray = value.split('/');
		for (let x = 0; x < valueArray.length; x++) {
			pathArray[position + x] = valueArray[x];
		}
	}
	if (lastpos) pathArray = pathArray.slice(0, lastpos + 1);
	return pathArray.join('/');
}
export function pushOrReplace(history, path, position, location) {
	let currentSegments = path.split("/").length;
	if (currentSegments === position) {
		history.push({ pathname: location });
	} else {
		history.replace({ pathname: location });
	}
}

/* LINK URLS ----------------------------------------------------------------------------------------*/

export function workOrderURLfromID(handle, appId, workOrder, callback) {
	if (appId == APPS.SERVICE.id) {
		callback([
			`/${handle}/${appId}/customers/${workOrder.customerId ?? '0'}/form/0/service_requests`,
			`/${handle}/${appId}/customers/${workOrder.customerId ?? '0'}/form/0/service_requests/${workOrder.serviceRequestId}/work_orders`,
			`/${handle}/${appId}/customers/${workOrder.customerId ?? '0'}/form/0/service_requests/${workOrder.serviceRequestId}/work_orders/${workOrder.id}/items`
		]);
	} else if (appId == APPS.ASSETS.id) {
		callback([
			`/${handle}/${appId}/assets/${workOrder.customerId ?? '0'}/${workOrder.assetTypeId}/${workOrder.assetId}/form/0/service_requests`,
			`/${handle}/${appId}/assets/${workOrder.customerId ?? '0'}/${workOrder.assetTypeId}/${workOrder.assetId}/form/0/service_requests/${workOrder.serviceRequestId}/work_orders`,
			`/${handle}/${appId}/assets/${workOrder.customerId ?? '0'}/${workOrder.assetTypeId}/${workOrder.assetId}/form/0/service_requests/${workOrder.serviceRequestId}/work_orders/${workOrder.id}/items`
		]);
	}
}
export function serviceRequestURL(handle, appId, serviceRequest, callback) {
	if (appId == APPS.SERVICE.id) {
		callback([
			`/${handle}/${appId}/customers/${serviceRequest.customerId ?? '0'}/form/0/service_requests`,
			`/${handle}/${appId}/customers/${serviceRequest.customerId ?? '0'}/form/0/service_requests/${serviceRequest.id}/work_orders`,
		]);
	} else if (appId == APPS.ASSETS.id) {
		callback([
			`/${handle}/${appId}/assets/${serviceRequest.customerId ?? '0'}/${serviceRequest.assetTypeId}/${serviceRequest.assetId}/form/0/service_requests`,
			`/${handle}/${appId}/assets/${serviceRequest.customerId ?? '0'}/${serviceRequest.assetTypeId}/${serviceRequest.assetId}/form/0/service_requests/${serviceRequest.id}/work_orders`,
		]);
	}
}
export function selectApp(appId, routeLocation, history) {
    const segments = routeLocation.pathname.split('/');
	const cutoff = 3;

	if (segments[3] == 'schedule') cutoff = 4;
	if (segments[3] == 'employees') cutoff = 4;

	let location = toolbox.modifyPath(routeLocation?.pathname, 2, appId, cutoff);

	// Switching from Assets to Customers -- ensure Asset menu not selected
	if (appId == APPS.SERVICE.id && segments[3] == 'assets') {
		location = toolbox.modifyPath(location, 3, 'customers', 3);
	}
	history.replace({ pathname: location });
}
export function selectLocation(handle, routeLocation, history) {
	let location = toolbox.modifyPath(routeLocation?.pathname, 1, handle, 3);
	history.replace({ pathname: location });
}

/* UTILITY ----------------------------------------------------------------------------------------*/

export function numPanels(x) {
	const screenWidth = document.body.clientWidth
	const numPanels = (screenWidth < 992) ? 1 : (screenWidth < 1200) ? 2 : 3;

	return (x) ? (numPanels == x) : numPanels;
}
export function matchURL(url, nav, match, appId) {
	var match = nav.path;
	var matchAppId = nav.appId;
	var urlSegments = url.split('/');
	var matchSegments = match.split('/');
	var result = (matchAppId) ? (appId == matchAppId) : true;
	for (let i = 0; i < matchSegments.length; i++) {
		if (i < (urlSegments.length - 3)) {
			if (matchSegments[i] != '*' && urlSegments[i+3] != matchSegments[i]) result = false;
		} else {
			result = false;
		}
	} 
	return result;
}
export function sortByPosition(arr) {
	return arr.sort(function (a, b) {
		var x = a.position; var y = b.position;
		return ((x < y) ? -1 : ((x > y) ? 1 : 0));
	});
}
export function sortByField(arr, field, reverse) {
	if (!reverse) return arr?.sort((a, b) => (a[field] > b[field]) ? 1 : ((b[field] > a[field]) ? -1 : 0));
	else return arr?.sort((a, b) => (a[field] < b[field]) ? 1 : ((b[field] < a[field]) ? -1 : 0));
}
export function imageSize(filename, code) {
	if (filename) {
		let period = filename.lastIndexOf('.');
		let first = filename.substring(0, period);
		let ext = filename.substring(period + 1);

		let size = '';
		if (code == 'sm') size = '_250x250';
		if (code == 'md') size = '_1000x1000';

		return `${first + size}.${ext}`;
	} else {
		return filename;
	}
}
export function roundDateTime(date, duration) {
	return moment(Math.ceil((+date) / (+duration)) * (+duration));
}
export function imageResize() {}

/* FORMAT ----------------------------------------------------------------------------------------*/

export function UCFirst(string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}
export function humanize(str) {
	if (str) {
		var i, frags = str.split('_');
		for (i = 0; i < frags.length; i++) {
			frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
		}

		return frags.join(' ');
	}
}
export function format_address(address1, address2, city, state, zip, phone, email, commas, newline) {

	var address = [];

	if (address1) address.push(address1 + ((commas) ? ', ' : ''));
	if (address1 && newline) address.push(<br key={'address1' + address1} />);

	if (address2) address.push(address2 + ((commas) ? ', ' : ''));
	if (address2 && newline) address.push(<br key={'address2' + address2} />);

	if (city) address.push(city + ((state || zip) ? ', ' : ''));
	if (state) address.push(state + ((zip) ? ' ' : ''));
	if (zip) address.push(zip);
	if (city || state || zip) address.push(<br key={'citystatezip' + city + state + zip} />);
	if (city || state || zip) address.push(<br key={'citystatezip2' + city + state + zip} />);

	if (phone) {
		address.push('Phone: ');
		address.push(<a key={'phonelink' + phone} href="tel:'+phone+'">{phone}</a>);
		address.push((commas) ? ', ' : '');
	}
	if (phone && newline) address.push(<br key={'phone' + phone} />);

	if (email) {
		address.push('Email: ');
		address.push(<a key={'emaillink' + email} href={'mailto:' + email}>{email}</a>);
		address.push((commas) ? ', ' : '');
	}
	if (email && newline) address.push(<br key={'email' + email} />);

	return address;
}
export function formatNumber(amount, decimalCount = 2, usd = false) {

	decimalCount = Math.abs(decimalCount);
	decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

	const negativeSign = amount < 0 ? "-" : "";

	let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
	let j = (i.length > 3) ? i.length % 3 : 0;

	return ((usd) ? '$':'') + negativeSign + (j ? i.substr(0, j) + ',' : '') + (i.substr(j).replace(/(\d{3})(?=\d)/g, "$1,")) + (decimalCount ? '.' + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
};
export function removeWhitespace(string) {
	return string.replace(/\s+/g, ' ').trim();
}
export function dateOrDash(date, format = 'MM/DD/YYYY') {
	if (!date || date == 'Invalid date') return '-';
	if (date instanceof Date) return moment(date).format(format);
	if (date instanceof moment) return date.format(format);
	return moment(date?.toDate()).format(format);
}
export function toProperCase(string) {
	if (!string) return;
	if (typeof value !== 'string') return string;
	return string.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
};
export function sanitize(record, fields) {
	if (record == null || record == undefined) record = {};
	let santized = {};
	for (const [key, value] of Object.entries(record)) {
		if (fields) {
			if (fields.includes(key) && value !== undefined) santized[key] = (typeof value === 'string') ? value.trim() : value;
		} else {
			if (value !== undefined) santized[key] = (typeof value === 'string') ? value.trim() : value;
		}
	} 
	return santized;
}
export function createHandle(string) {
	return string.replace(/\s+/g, ' ').trim().replace(/ /g, "_").replace(/\W/g, '').toLowerCase();
}
export function getOrdinal(n) {
	let ord = 'th';

	if (n % 10 == 1 && n % 100 != 11) {
		ord = 'st';
	}
	else if (n % 10 == 2 && n % 100 != 12) {
		ord = 'nd';
	}
	else if (n % 10 == 3 && n % 100 != 13) {
		ord = 'rd';
	}

	return ord;
}
export function employeeNameFromId(employees, employeeId) {
	const employee = employees.find((employee) => employee.id == employeeId);
	const name = (employee) ? `${employee?.contact?.firstName} ${employee?.contact?.lastName}` : 'UNASSIGNED';
	return name;
}
export function removeMark(value) {
	value = value.replaceAll('<mark>','');
	value = value.replaceAll('</mark>','');
	return value;
}
