import * as actions_service_requests from 'actions/service-requests-actions';
import * as actions_settings from 'actions/settings-actions';
import * as toolbox from 'components/common/toolbox';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Avatar, Ibox, Table } from 'enspire-manager-framework';
import { APPS, REQUESTS } from 'components/common/constants';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

var moment = require('moment');

const ServiceRequestsSearch = (props) => {

    /* Hooks --------------------------*/

	const seg = 4;
    const params = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
	const routeLocation = useLocation();

    const settings = useSelector((store) => store.settings);
	const services = useSelector((store) => store.serviceRequests);
	const serviceRequests = useSelector((store) => store.serviceRequests.service_requests);

    const [data, setData] = useState([]);

    /* UseEffect --------------------------*/

    useEffect(() => {
        if (props.type == 'open') {
            dispatch(actions_service_requests.subOpenServiceRequests(params.handle, params.appId));
        } else {
            dispatch(actions_service_requests.subService(params.handle, params.appId));
        }
        
        return () => {
			const unsubscribe = services.service_requests_unsubscribe;
			if (typeof unsubscribe == 'function') unsubscribe();
		};
    }, [params.handle, params.appId]);

    useEffect(() => {
        var service_requests = [];
        services?.service_requests?.forEach((serviceRequest) => {
            let status = _.find(Object.values(REQUESTS), { id: serviceRequest.statusId });
            service_requests.push({
                ...serviceRequest,
                status: status?.name,
                image: <Avatar className="mx-auto"
                    color="white"
                    bgColor="saddleBrown"
                    size={45}
                    fontSize={20}
                    name={serviceRequest._name}
                    image={serviceRequest._imageUrl}
                    imageWidth={60}
                    border="4px solid white"
                    role={ (serviceRequest._imageUrl) ? "button" : null }
                />,
                _stripe_color: status?.color,
            });
        });
        setData(service_requests);
    }, [services?.service_requests]);


    /* Actions ----------------------------*/

    const openServiceRequest = (serviceRequest) => {
        toolbox.serviceRequestURL(params.handle, params.appId, serviceRequest, (locations) => {
            if (settings.dualMonitor2) {
                dispatch(actions_settings.updateSettings(params.handle, {
                    'dualMonitor1Url': `${locations[0]}|${locations[1]}}`
                }));
            } else {
                history.push({ pathname: locations[0] });
                history.push({ pathname: locations[1] });
            }
        });
    }

    /* Constants --------------------------*/
    
    const columns = [
        ...(params.appId == APPS.SERVICE.id) ? [{ name: 'Customer', field: '_displayName', width: 60 }] : [],
        ...(params.appId == APPS.ASSETS.id) ? [{ name: "UNIT #", field: '_unitNumber', nowrap: true, width: 10 }] : [],
        ...(params.appId == APPS.ASSETS.id) ? [{ name: 'Asset', field: '_name', width: 50 }] : [],
        // { name: "Total Cost", field: "totalCost", type: 'number', format: 'usd', width: 20 },
        { name: 'Requested', field: 'requestDate', type: 'date', format: 'MMM Do, YYYY', nowrap: true, width: 20 },
        { name: 'Summary', field: 'description' },
    ];

    const title = "Service Requests / Records";

    return (
        <Ibox className="mt-3 mb-5" title={title} show_spinner={serviceRequests.work_orders_pending} no_fade={true}>
            <Table
                data={data}
                columns={columns}
                active_field={'id'}
                active_id={params.request_id}
                second_line={'description'}
                image={(params.appId == APPS.ASSETS.id) ? 'image' : null}
                imageWidth={45}
                group_by={{ fields: ['statusId'], direction: ['asc'], display: ['status'] }}
				click_callback={ (request) => {
                    if (params.appId == APPS.SERVICE.id) {
                        toolbox.listLink(history, routeLocation?.pathname, seg, `${request.customerId}/form/0/service_requests/${request.id}`, "work_orders", seg+5);
                    } else if (params.appId == APPS.ASSETS.id) {
                        toolbox.listLink(history, routeLocation?.pathname, seg, `${request.assetId}/form/0/service_requests/${request.id}`, "work_orders", seg+5);
                    }
				}}
                show_search={true}
                chevron={true}
                highlight_search={true}
                limit={10}
            >
            </Table>
        </Ibox>
    );
};

export default ServiceRequestsSearch;