import * as types from 'actions/action-types';

var initialState = {
	archived_asset_types: [],
	archived_asset_types_pending: false,
	archived_asset_types_save_pending: false,
	archived_asset_types_unsubscribe: false,

	archived_assets: [],
	archived_assets_pending: false,
	archived_assets_save_pending: false,
	archived_assets_unsubscribe: false,

	archived_profiles: [],
	archived_profiles_pending: false,
	archived_profiles_save_pending: false,
	archived_profiles_unsubscribe: false,

	archived_inventory: [],
	archived_inventory_pending: false,
	archived_inventory_save_pending: false,
	archived_inventory_unsubscribe: false,

	archived_service_requests: [],
	archived_service_requests_pending: false,
	archived_service_requests_save_pending: false,

	// archived_work_orders: null,
	// archived_work_orders_pending: false,
	// archived_work_orders_save_pending: false,

	// archived_service_items: null,
	// archived_service_items_pending: false,
	// archived_service_items_save_pending: false,
};

const archiveReducer = (state = initialState, action) => {

	switch (action.type) {
		case types.ARCHIVED_ASSET_TYPES + '_PENDING': pending('archived_asset_types'); break;
		case types.ARCHIVED_ASSET_TYPES + '_SAVE_PENDING': save_pending('archived_asset_types'); break;
		case types.ARCHIVED_ASSET_TYPES + '_FULFILLED': fulfilled('archived_asset_types'); break;

		case types.ARCHIVED_ASSETS + '_PENDING': pending('archived_assets'); break;
		case types.ARCHIVED_ASSETS + '_SAVE_PENDING': save_pending('archived_assets'); break;
		case types.ARCHIVED_ASSETS + '_FULFILLED': fulfilled('archived_assets'); break;

		case types.ARCHIVED_PROFILES + '_PENDING': pending('archived_profiles'); break;
		case types.ARCHIVED_PROFILES + '_SAVE_PENDING': save_pending('archived_profiles'); break;
		case types.ARCHIVED_PROFILES + '_FULFILLED': fulfilled('archived_profiles'); break;

		case types.ARCHIVED_INVENTORY + '_PENDING': pending('archived_inventory'); break;
		case types.ARCHIVED_INVENTORY + '_SAVE_PENDING': save_pending('archived_inventory'); break;
		case types.ARCHIVED_INVENTORY + '_FULFILLED': fulfilled('archived_inventory'); break;

		case types.ARCHIVED_SERVICE_REQUESTS + '_PENDING': pending('archived_service_requests'); break;
		case types.ARCHIVED_SERVICE_REQUESTS + '_SAVE_PENDING': save_pending('archived_service_requests'); break;
		case types.ARCHIVED_SERVICE_REQUESTS + '_FULFILLED': fulfilled('archived_service_requests'); break;

		// case types.ARCHIVED_WORK_ORDERS + '_PENDING': pending('archived_work_orders'); break;
		// case types.ARCHIVED_WORK_ORDERS + '_SAVE_PENDING': save_pending('archived_work_orders'); break;
		// case types.ARCHIVED_WORK_ORDERS + '_FULFILLED': fulfilled('archived_work_orders'); break;

		// case types.ARCHIVED_SERVICE_ITEMS + '_PENDING': pending('archived_service_items'); break;
		// case types.ARCHIVED_SERVICE_ITEMS + '_SAVE_PENDING': save_pending('archived_service_items'); break;
		// case types.ARCHIVED_SERVICE_ITEMS + '_FULFILLED': fulfilled('archived_service_items'); break;
		default: break;
	}

	return state;

	function pending(table) {
		state = {
			...state,
			[table + '_pending']: true,
		};
	}
	function fulfilled(table) {
		state = {
			...state,
			[table]: action.data,
			[table + '_pending']: false,
			[table + '_save_pending']: false,
			[table + '_unsubscribe']: (action.unsubscribe) ? action.unsubscribe : state[table + '_unsubscribe'],
		};
	}
	function save_pending(table) {
		state = {
			...state,
			[table + '_save_pending']: true,
		};
	}
};
export default archiveReducer;


