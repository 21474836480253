import * as actions_schedule from 'actions/schedule-actions';
import * as actions_settings from 'actions/settings-actions';
import * as actions_users from 'actions/users-actions';
import * as actions_work_orders from 'actions/work-orders-actions';
import * as toolbox from 'components/common/toolbox';
import React, { useEffect, useState } from 'react';
import StatusSelect from 'components/common/status-select';
import _ from 'lodash';
import { CHECKIN, WORKORDERS } from 'components/common/constants';
import { Ibox, Table } from 'enspire-manager-framework';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

const moment = require('moment');

const OpenWorkOrders = (props) => {
    
    /* Hooks --------------------------*/
    
    const seg = 3;
    const params = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const routeLocation = useLocation();

    const users = useSelector((store) => store.users);
    const user = users.user;
    const employees = useSelector((store) => store.employees.employees);
    const settings = useSelector((store) => store.settings);
    const workOrders = useSelector((store) => store.workOrders);

    const [data, setData] = useState([]);
    const isSchedule = (props.source == 'schedule');
    const isTechnician = (props.source == 'technician');

    /* UseEffect --------------------------*/

    useEffect(() => {
        let employee = _.find(employees, (o) => { return o.contact?.email == user.id });
        if (isSchedule) dispatch(actions_work_orders.subOpenWorkOrders(params.handle, params.appId));
        if (isTechnician) dispatch(actions_work_orders.subTechnicianWorkOrders(params.handle, employee?.id, params.appId));
        
        return () => {
			const unsubscribe = workOrders.work_orders_open_unsubscribe;
			if (typeof unsubscribe == 'function') unsubscribe();
		};
    }, [params.handle, params.appId, user.id]);

    useEffect(() => {
        
        var work_orders = [];
        workOrders?.work_orders_open?.forEach((workOrder) => {
            
            let status = _.find(Object.values(WORKORDERS), { id: workOrder.statusId });
            let employee = employees?.find((o) => o.id == workOrder.technicianId);
            let checkinTypes = (workOrder?.offsite)
            ?   _.filter(Object.values(CHECKIN), (o) => { return o.id != CHECKIN.INPROGRESS.id })
            :   _.filter(Object.values(CHECKIN), (o) => { return o.id != CHECKIN.ENROUTE.id && o.id != CHECKIN.ONSITE.id });

            work_orders.push({
                ...workOrder,
                name: ((employee)
                    ? employee?.contact?.firstName + ' ' + employee?.contact?.lastName
                    : (workOrder.statusId == WORKORDERS.UNASSIGNED.id) ? 'Unassigned' : 'Unknown'),
                status: status?.name,
                checkin: 
                    <StatusSelect
                        selectedStatusId={workOrder.statusId}
                        statusTypes={checkinTypes}
                        statusTable={'CHECKIN'}
                        id={workOrder.id}
                        onClick={handleCheckin}
                    />,
                _stripe_color: status?.color,
                _disabled: [ true, false ],
            });
        });
        setData(work_orders);
    }, [workOrders?.work_orders_open]);
    

    /* Actions ----------------------------*/

    const handleCheckin = (table, checkinId, workOrderId) => {
        const workOrder = _.find(workOrders.work_orders_open, { id: workOrderId });
        dispatch(actions_work_orders.workOrderCheckin(params.handle, workOrder, user, checkinId, () => {
            dispatch(actions_users.clockIn(params.handle, user.id));
        }));
    };
    const viewSchedule = (workOrderId) => {
        let workOrder = _.find(workOrders?.work_orders_open, { id: workOrderId });
        let newLocation = toolbox.modifyPath(routeLocation.pathname, seg, 'schedule/' + moment(workOrder.startDate.seconds, 'X').format('YYYY-MM-DD') + '/' + workOrder.technicianId, seg+3);
        history.push({ pathname: newLocation });
    };
    const openWorkOrder = (workOrder) => {
        toolbox.workOrderURLfromID(params.handle, params.appId, workOrder, (locations) => {
            if (settings.dualMonitor2) {
                props.setState({ popoverTarget: null });
                dispatch(actions_schedule.cancelNewEvent());

                dispatch(actions_settings.updateSettings(params.handle, {
                    'dualMonitor1Url': `${locations[0]}|${locations[1]}|${locations[2]}`
                }));
            } else {
                history.push({ pathname: locations[0] });
                history.push({ pathname: locations[1] });
                history.push({ pathname: locations[2] });
            }
        });
    }

    /* Constants --------------------------*/

    const columns = [
        { name: 'Start', field: 'startDate', type: 'timestamp', format: 'MMM Do', width: 20 },
        { name: 'Technician', field: 'name', width: (isTechnician) ? 30 : 40 },
        { name: 'Asset', field: '_name', width: (isTechnician) ? 30 : 40 },
        ...(isTechnician) ? [{ name: 'Check-In', field: 'checkin', type: 'jsx', width: 20 }] : [],
        { name: 'Summary', field: 'description' },
        {
            name: '',
            field: 'id',
            type: 'actions',
            multiple: true,
            button: {
              name: <span className="material-icons">more_vert</span>,
              className: 'px-0 text-muted',
              activeClass: 'btn-success',
              links: [
                ...(isSchedule) ? [{ name: 'Move to Current Date', callback: (event) => { event.stopPropagation(); }}] : [],
                { name: 'View on Schedule', callback: viewSchedule },
                // { name: <span className="text-danger">Delete Work Order</span>, callback: (event) => { event.stopPropagation(); } },
              ]
            },
        },
    ];

    const title = (isTechnician) ? "My Work Orders" : "Open Work Orders";

    return (
        <Ibox className="mt-3 mb-0" title={title} show_spinner={workOrders.work_orders_pending} no_fade={true}>
            <Table
                data={data}
                table_style={{ minWidth: '400px', overflowX: 'scroll' }}
                columns={columns}
                active_field={'id'}
                active_id={params.work_order_id}
                second_line={'description'}
                group_by={{ fields: ['statusId'], direction: ['desc'], display: ['status'] }}
                click_callback={openWorkOrder}
                show_search={true}
                chevron={true}
                highlight_search={true}
                limit={10}
            >
            </Table>
        </Ibox>
    );
};

export default OpenWorkOrders;