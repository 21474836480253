import CatchErrors from 'components/common/catch-errors';
import DashboardSelect from 'components/dashboard/dashboard-select';
import ServiceRequestsSearch from 'components/service-requests/service-requests-search';
import OpenWorkOrders from 'components/work-orders/open-work-orders';
import React from 'react';
import { useParams } from 'react-router-dom';

import { Widget } from 'enspire-manager-framework';

export default function DashboardRoutes(props) {
	
    const params = useParams();

    return (

		<>
			<DashboardSelect index={ props.index } />

			{ params['dashboard' + (parseInt(props.index) + 1)] == 'technician' &&
				<CatchErrors className={'m-t-lg'}>
					<OpenWorkOrders source="technician" />
				</CatchErrors>
			}
			
			{ params['dashboard' + (parseInt(props.index) + 1)] == 'service_requests' &&
				<CatchErrors className={'m-t-lg'}>

					<div className="row mb-2 no-gutters" >
						<div className="col-6 pr-1">
							<Widget 
								className="animated fadeIn animation-delay-1"
								type={ 2 } 
								color_number={ 1 } 
								spinnerColor={ 'white' } 
								fetching={ false }
								icon={ 'fas fa-question-circle fa-3x' }
								text={ 'Service Requests' }
								title={ (0)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }
							/>
						</div>
						<div className="col-6 pl-1">
							<Widget 
								className="animated fadeIn animation-delay-2"
								type={ 2 } 
								color_number={ 3 } 
								spinnerColor={ 'white' } 
								fetching={ false }
								icon={ 'fas fa-dollar-sign fa-3x' }
								text={ 'Total Service Cost' }
								title={ '$' + (0)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }
							/>
						</div>
					</div>
					<ServiceRequestsSearch type="open" />
				</CatchErrors>
			}
		</>
	);
};
