import * as toolbox from 'components/common/toolbox';
import * as types from './action-types';
import firebase from 'firebase/compat/app';
import moment from 'moment';

const firestore = firebase.firestore();

/*-----------------------------------------------*/
/* AUTHENTICATION
/*-----------------------------------------------*/

export function login(email, password, firebase, callback) {

	return dispatch => {

		firebase.auth().signInWithEmailAndPassword(email.trim(), password.trim()).catch((error) => {
			toolbox.process_error(error, 'Login: ' + error.message);
			if (typeof callback === "function") callback();
		});
	};
}
export function logout() {

	return dispatch => {

		firebase.auth().signOut().then(() => {
		}).catch((error) => {
			toolbox.process_error(error, 'Logout: ' + error.message);
		});
	};
}
export function validateLogin(handle, user, callback) {

	return dispatch => {

		Promise.all([
			firestore.collection('_companies').doc(handle).get(),
			firestore.collection(handle + '/users/users').doc(user.email).get(),
		]).then((query) => {

			var docCompany = { ...query[0].data() };
			var userData = { ...query[1].data() };

			if (window.flutter) window.flutter.callHandler('setAppId', docCompany.appIds[0]); 
		
			if (userData?.activated && userData?.roles?.length > 0) {
				localStorage.setItem('mobileTrackHandle', handle);
				if (window.flutter) window.flutter.callHandler('handle', handle);
				if (typeof callback === "function") callback('success');

			} else {
				if (userData?.roles?.length > 0) {

					firestore.collection(handle + '/users/users/').doc(user.email).update({ 'activated': true, 'activatedDate': moment().toDate() }).then(() => {
						window.toastr.info('User Validated with ' + docCompany.companyName, 'VALIDATED!');
						localStorage.setItem('mobileTrackHandle', handle);
						if (window.flutter) window.flutter.callHandler('handle', handle);
						if (typeof callback === "function") callback('success');
					}).catch((error) => {
						toolbox.process_error(error, 'Validation Update: ' + error.message);
					});

				} else {
					if (typeof callback === "function") callback('not_found');
				}
			}
		}).catch((error) => {
			toolbox.process_error(error, 'Validate Login: ' + error.message);
		});
	};
}
export function sendEmployeeInvitationEmail(handle, company, contact, callback) {

	var email = `			
		<h2>Mobile Track Systems.</h2>
		<p>${contact.firstName} ${contact.lastName},</p>
	
		<p>This is your invitation to register as a User of the Mobile Track application. If you have any questions, please contact us at ` + company.phone + `. Follow the link below for completing your registration.</p>
		<br/>

		<a href='https://assetmanager.mobiletrack.systems/#/` + handle + '/register_user/' + contact.firstName + '/' + contact.lastName + '/' + contact.email + `' style="color: white; background-color: green; padding: 8px 25px; border-radius: 20px; text-decoration: none">REGISTER NOW</a>

		<br/> <br/>

		<p>In the future, you can login at <a href="https://assetmanager.mobiletrack.systems/#/${handle}/login">https://assetmanager.mobiletrack.systems/#/${handle}/login</a>.</p>
		<br/>`;

	return async dispatch => {

		if (contact.email) {
			dispatch({ type: types.SEND_EMAIL + '_PENDING' });

			// firebase.functions().useEmulator('localhost', 5001);
			var sendInvitation = firebase.functions().httpsCallable('sendEmail');
			sendInvitation({
				from: (company.email) ? company.companyName + ' <' + company.email + '>' : 'Mobile Track Systems <no-reply@mobiletrack.systems>',
				to: contact.email,
				subject: 'Invitation to join ' + toolbox.toProperCase(handle) + ' Employee Access',
				text: email,
				html: email,

			}).then(async (response) => {

				if (response.data.statusCode >= 200 && response.data.statusCode < 300) {
					await firestore.collection(handle + '/users/users').doc(contact.email).set({ invitationSent: new Date() }, { merge: true }).then(() => {
						dispatch({ type: types.SEND_EMAIL + '_FULFILLED' });
						window.toastr.success('An invitation email has been successfully sent to ' + contact.email, 'Invitation Email Sent!');
					}).catch((error) => {
						toolbox.process_error(error, 'Email invitation NOT Saved!');
					});
				} else {
					window.toastr.error('Something went wrong sending invitation eamil to ' + contact.email, 'Invitation Email NOT Sent!');
				}
				if (typeof callback === "function") callback();
			});
		}
	};
}
export function sendPasswordReset(email, callback) {

	return dispatch => {

		var auth = firebase.auth();

		auth.sendPasswordResetEmail(email).then(function () {
			window.toastr.success('A password reset email has been sent to ' + email, 'PASSWORD RESET');
		}).catch(function (error) {
			toolbox.process_error(error, 'Password Reset: ' + error.message);
		});
		if (typeof callback === "function") callback();
	};
}
