import * as toolbox from 'components/common/toolbox';
import * as types from './action-types';
import firebase from 'firebase/compat/app';
import { APPS, QUOTES, REQUESTS, SERVICE_ITEMS, SERVICE_REQUEST_TERMS, SCHEDULE_EVENT_TYPE, WORKORDERS } from 'components/common/constants';

const firestore = firebase.firestore();
const _ = require('lodash');
var moment = require('moment');
const axios = require('axios');

/*-----------------------------------------------*/
/*  SERVICE REQUESTS
/*-----------------------------------------------*/

export function subServiceRequests(handle, customerId) {

	return dispatch => {

		dispatch({ type: types.SERVICE_REQUESTS + '_PENDING' });

		var unsubscribe = firestore.collection(handle + '/service-requests/service-requests')
			.where('type', '==', 'service_request')
			.where('customerId', '==', customerId)
			.where('deleted', '==', false).onSnapshot((querySnapshot) => {

			var requests = [];
			querySnapshot.forEach((doc) => {
				requests.push({ ...doc.data(), id: doc.id });
			});
			requests = _.orderBy(requests, ['created'], ['desc']);

			dispatch({ type: types.SERVICE_REQUESTS + '_FULFILLED', data: requests, unsubscribe });
		});
	};
}
export function subAssetServiceRequests(handle, asset_id) {

	return dispatch => {

		dispatch({ type: types.SERVICE_REQUESTS + '_PENDING' });

		var unsubscribe = firestore.collection(handle + '/service-requests/service-requests')
			.where('type', 'in', ['asset_request', 'asset_record'])
			.where('assetId', '==', asset_id)
			.where('deleted', '==', false)
			.onSnapshot((querySnapshot) => {
				var requests = [];
				querySnapshot.forEach((doc) => {
					requests.push({ ...doc.data(), id: doc.id });
				});
				requests = _.orderBy(requests, ['created'], ['desc']);

				dispatch({ type: types.SERVICE_REQUESTS + '_FULFILLED', data: requests, unsubscribe });
			}
		);
	};
}
export function subOpenServiceRequests(handle, appId) {

	return async dispatch => {

		dispatch({ type: types.SERVICE_REQUESTS + '_PENDING' });

		let requestType = _.find(Object.values(APPS), { id: appId }).requestType;

		var unsubscribe = firestore.collection(handle + '/service-requests/service-requests').where('deleted', '==', false).where('statusId', 'in', [
			REQUESTS.UNASSIGNED.id,
			REQUESTS.ASSIGNED.id, 
			REQUESTS.INCOMPLETE.id
		]).where('type', '==', requestType).onSnapshot(async(querySnapshot) => {
			var requests = [];
			var assetIds = [];
			querySnapshot.forEach((doc) => {
				requests.push({ ...doc.data(), id: doc.id });
				assetIds.push(doc.data().assetId);
			});

			// // Retrieve Asset Details and merge with Request
			// var assets = [];
			// for (let assetId of assetIds) {
			// 	var doc = await firestore.collection(handle + '/assets/assets').doc(assetId).get();
			// 	assets.push({ ...doc.data(), id: doc.id });
			// }

			// requests.forEach((request, index) => {
			// 	let asset = _.find(assets, { id: request.assetId });
			// 	requests[index] = { ...asset, ...request }
			// });
			// requests = _.orderBy(requests, ['created'], ['desc']);

			dispatch({ type: types.SERVICE_REQUESTS + '_FULFILLED', data: requests, unsubscribe });
		});
	};
}
export function subService(handle, appId) {

	return async dispatch => {

		dispatch({ type: types.SERVICE_REQUESTS + '_PENDING' });

		let serviceTypes = _.find(Object.values(APPS), { id: appId }).serviceTypes;

		var unsubscribe = firestore.collection(handle + '/service-requests/service-requests').where('deleted', '==', false)
		.where('type', 'in', serviceTypes).orderBy('created', 'desc').limit(100).onSnapshot(async(querySnapshot) => {
			var requests = [];
			var assetIds = [];
			querySnapshot.forEach((doc) => {
				requests.push({ ...doc.data(), id: doc.id });
				assetIds.push(doc.data().assetId);
			});

			// Retrieve Asset Details and merge with Request
			// var assets = [];
			// for (let assetId of assetIds) {
			// 	var doc = await firestore.collection(handle + '/assets/assets').doc(assetId).get();
			// 	assets.push({ ...doc.data(), id: doc.id });
			// }
			
			// let index = 0;
			// for (let request of requests) {

			// 	let asset = _.find(assets, { id: request.assetId });
								
			// 	var update = {
			// 		...(asset.unitNumber) ? { _unitNumber: asset.unitNumber } : {},
			// 		...(asset.imageUrl) ? { _imageUrl: asset.imageUrl } : { _imageUrl: null },
			// 	}
	
			// 	// if (!request.unitNumber) {
			// 		await firestore.collection(handle + '/service-requests/service-requests').doc(request.id).update(update);
			// 		console.log(update);
			// 	// }

			// 	index++;
			// }

			dispatch({ type: types.SERVICE_REQUESTS + '_FULFILLED', data: requests, unsubscribe });
		});
	};
}
export function subServiceRequest(handle, id) {

	return dispatch => {

		dispatch({ type: types.SERVICE_REQUEST + '_PENDING' });

		var unsubscribe = firestore.collection(handle + '/service-requests/service-requests').doc(id).onSnapshot((doc) => {
			var request = { ...doc.data(), id: doc.id };

			dispatch({ type: types.SERVICE_REQUEST + '_FULFILLED', data: request, unsubscribe });
		});
	};
}
export function clearServiceRequest() {

	return dispatch => {

		dispatch({ type: types.SERVICE_REQUEST + '_CLEAR' });
	};
}
export function convertToServiceCall(handle, serviceRequest, workOrder, quote, callback) {

	return async dispatch => {

		dispatch({ type: types.SERVICE_REQUEST + '_SAVE_PENDING' });

		const batch = firestore.batch();
		var serviceRequestId = serviceRequest.id;
		delete serviceRequest.id;
		serviceRequest.modified = new Date();

		// Copy line items to work Order
		batch.set(firestore.collection(handle + '/work-orders/work-orders/' + workOrder.id + '/serviceItems').doc(workOrder.id), { serviceItems: quote?.serviceItems });
		// Delete quote 
		batch.delete(firestore.collection(handle + '/quotes/quotes').doc(quote?.id));

		// Switch to Service Call
		batch.update(firestore.collection(handle + '/service-requests/service-requests').doc(serviceRequestId), { ...serviceRequest, serviceRequestTermsId: SERVICE_REQUEST_TERMS.SERVICE_CALL.id });

		batch.commit().then(() => {
			dispatch({ type: types.SERVICE_REQUEST + '_SAVE_FULFILLED' });
			window.toastr.success('The Service Request has been successfully saved/updated', 'Service Request Saved!');
			if (typeof callback === 'function') callback();

		}).catch((error) => {
			toolbox.process_error(error, 'Record NOT Saved!');
		});
	};
}
export function convertToEstimate(handle, serviceRequest, callback) {

	return async dispatch => {

		dispatch({ type: types.SERVICE_REQUEST + '_SAVE_PENDING' });

		const batch = firestore.batch();
		var serviceRequestId = serviceRequest.id;
		delete serviceRequest.id;
		serviceRequest.modified = new Date();

		// Switch to Estimate
		batch.update(firestore.collection(handle + '/service-requests/service-requests').doc(serviceRequestId), { ...serviceRequest, serviceRequestTermsId: SERVICE_REQUEST_TERMS.ESTIMATE.id });

		batch.commit().then(() => {
			dispatch({ type: types.SERVICE_REQUEST + '_SAVE_FULFILLED' });
			window.toastr.success('The Service Request has been successfully saved/updated', 'Service Request Saved!');
			if (typeof callback === 'function') callback();

		}).catch((error) => {
			toolbox.process_error(error, 'Record NOT Saved!');
		});
	};
}
export function saveServiceRequest(handle, request, settings, fromSchedule, callback) {

	return async dispatch => {

		dispatch({ type: types.SERVICE_REQUEST + '_SAVE_PENDING' });

		const isRecord = (request.type == 'asset_record');
		const batch = firestore.batch();

		if (isRecord) request.totalCompleted = request.totalCost;
		if (isRecord) request.completedDate = request.requestDate;

		var serviceRequestId = request.id;
		var technicianId = (request.technicianId != 'unassigned') ? request.technicianId : '';

		delete request.id;
		delete request.technicianId;
		
		request.modified = new Date();

		if (!parseInt(serviceRequestId)) {
			serviceRequestId = await nextServiceRequestId(handle, batch);
			var customId = await nextCustomId(handle, batch);
			if (request.assetId) {
				var doc = await firestore.collection(handle + '/assets/assets').doc(request.assetId).get();
				request._name = doc.data()?.['name'] ?? '';
				request._imageUrl = doc.data()?.['imageUrl'] ?? '';
				request._unitNumber = doc.data()?.['unitNumber'] ?? '';
			}
			if (request.customerId) {
				var doc = await firestore.collection(handle + '/profiles/profiles').doc(request.customerId).get();
				request._displayName = doc.data()?.['displayName'];
			}
			request.customId = customId;
			request.statusId = (technicianId) ? REQUESTS.ASSIGNED.id : REQUESTS.UNASSIGNED.id;
			request.totalCost = (request.totalCost) ? parseFloat(request.totalCost) : 0;
			request.totalCompleted = (request.totalCompleted) ? parseFloat(request.totalCompleted) : 0;
			request.statusId = (!isRecord) ? (technicianId) ? REQUESTS.ASSIGNED.id : REQUESTS.UNASSIGNED.id : REQUESTS.CLOSED.id;
			request.serviceRequestTermsId = (request.isServiceCall) ? SERVICE_REQUEST_TERMS.SERVICE_CALL.id : SERVICE_REQUEST_TERMS.ESTIMATE.id;
			request.deleted = false;
			request.created = new Date();

			var quote = {};
			if (request.serviceRequestTermsId == SERVICE_REQUEST_TERMS.ESTIMATE.id) {
				var quoteId = await nextQuoteId(handle, batch);
				quote._displayName = request?._displayName;
				quote.customId = request.customId;
				quote.serviceRequestId = serviceRequestId;
				quote.customerId = request.customerId;
				quote.startDate = request.requestDate;
				quote.endDate = request.endDate;
				quote.description = request.description;
				quote.serviceItems = [{ 
					id: "0000",
					type: SERVICE_ITEMS.LABOR.id,
					name: 'Labor',
					description: 'Description of Labor',
					count: 0,
					price: settings?.invoices?.defaultLaborRate ?? 0,
					calculatedPrice: (settings.invoices?.defaultLaborRate) ? parseFloat(settings?.invoices?.defaultLaborRate) : 0,
				}];
				quote.statusId = QUOTES.DRAFT.id;
				quote.options = ['1'];
				quote.approvedOption = '1';
				quote.estimatorId = '';
				quote.expiration = moment().add(30, 'days').toDate();
				quote.deleted = false;
				quote.created = new Date();
				quote.effective = new Date();
				quote.modified = new Date();

				if (request.assetId & request.assetTypeId) {
					quote.assetId = request.assetId;
					quote.assetTypeId = request.assetTypeId;
				} else {
					quote.addressId = request.addressId;
				}

				batch.set(firestore.collection(handle + '/quotes/quotes').doc(quoteId), { ...quote });
			}

			if (!isRecord && fromSchedule) {
				var { description, suggested, ...workOrder } = request;
				var workOrderId = await nextWorkOrderId(handle, batch);
				workOrder.customerId = request.customerId;
				workOrder.assetTypeId = request.assetTypeId;
				workOrder.assetId = request.assetId;
				workOrder._name = request._name;
				workOrder.serviceRequestId = serviceRequestId;
				workOrder.customId = request.customId;
				workOrder.description = request.description;
				workOrder.startDate = request.requestDate;
				workOrder.endDate = request.endDate; // provided by service request for this purpose only
				workOrder.technicianId = (technicianId) ? technicianId : 'unassigned';
				workOrder.statusId = (technicianId) ? WORKORDERS.ASSIGNED.id : WORKORDERS.UNASSIGNED.id;
				workOrder.type = request.type;
				workOrder.deleted = false;
				workOrder.created = new Date();
				workOrder.modified = new Date();
				workOrder.type = SCHEDULE_EVENT_TYPE.WORKORDER.id;
				workOrder.dates = scheduleDateArray(moment(request.startDate), moment(request.endDate));

				batch.set(firestore.collection(handle + '/work-orders/work-orders').doc(workOrderId), { ...workOrder });
			}
		}

		batch.set(firestore.collection(handle + '/service-requests/service-requests').doc(serviceRequestId), { ...request });

		batch.commit().then(() => {
			dispatch({ type: types.SERVICE_REQUEST + '_SAVE_FULFILLED' });
			window.toastr.success('The Service Request has been successfully saved/updated', 'Service Request Saved!');
			if (typeof callback === 'function') callback(serviceRequestId);

		}).catch((error) => {
			toolbox.process_error(error, 'Record NOT Saved!');
		});

	};
}
export function archiveServiceRequest(handle, serviceRequest, callback) {

	return async dispatch => {

		dispatch({ type: types.SERVICE_REQUEST + '_SAVE_PENDING' });

		var batch = firestore.batch();

		const serviceRequestRef = firestore.collection(handle + '/service-requests/service-requests');
		batch.set(serviceRequestRef.doc(serviceRequest.id), { deleted: true, modified: new Date() }, { merge: true });

		batch.commit().then(() => {
			dispatch({ type: types.SERVICE_REQUEST + '_SAVE_FULFILLED' });
			window.toastr.success('The Service Request has been Archived', 'Service Request Archived!');
			if (typeof callback === 'function') callback(serviceRequest.id);
		}).catch((error) => {
			toolbox.process_error(error, 'Service Request NOT Archived!');
		});
	};
}
export function getServiceRequestMapCoordinates(address) {

	const map_address = address.addressLine1 + " " + address.addressLine2;

	return dispatch => {

		dispatch({ type: types['SERVICE_REQUEST_MAPBOX_SEARCH'] + '_PENDING' });
		const url_endpoint = 'geocoding/v5/mapbox.places/' + map_address + '.json';
		const access_token = 'pk.eyJ1IjoiYmxha2Vjb2RleiIsImEiOiJja2twemEyZ3ozMXZtMnVudzh6ajRkdG5wIn0.ZJ6dJHj6rJbOrDiBUuY2MA';
		axios({
			url: url_endpoint,
			method: 'get',
			baseURL: 'https://api.mapbox.com/',
			params: {
				access_token: access_token
			}
		}).then(response => {
			let coordinates = response.data.features[0].center;
			dispatch({ type: types['SERVICE_REQUEST_MAPBOX_SEARCH'] + '_FULFILLED', data: coordinates });
		});
	};
}

async function nextServiceRequestId(handle, batch) {
	const table = 'service-requests';
	const field = 'nextServiceRequestId';
	const startingId = 1000;

	return toolbox.nextId(handle, batch, table, field, startingId);
}
async function nextCustomId(handle, batch) {
	const table = 'service-requests';
	const field = 'nextCustomId';
	const startingId = 1000;

	return toolbox.nextId(handle, batch, table, field, startingId);
}
async function nextQuoteId(handle, batch) {
	const table = 'quotes';
	const field = 'nextQuoteId';
	const startingId = 1000;

	return toolbox.nextId(handle, batch, table, field, startingId);
}
async function nextWorkOrderId(handle, batch) {
	const table = 'work-orders';
	const field = 'nextWorkOrderId';
	const startingId = 1000;

	return toolbox.nextId(handle, batch, table, field, startingId);
}

function scheduleDateArray(starting, ending) {

	// DATE ARRAY
	var low = moment(starting).hour(0).minutes(0).seconds(0);
	var high = moment(ending).hour(23).minutes(59).seconds(59);
	var current = low.clone().hour(12);

	var i = 0;
	var dates = [];
	while (current.isBetween(low, high) && i < 90) {
		dates.push(current.format('MMDDYY'));
		current.add(1, 'day');
		i++;
	}
	return dates;
}

