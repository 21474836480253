import * as actions_admin from 'actions/admin-actions';
import * as toolbox from 'components/common/toolbox';
import CatchErrors from 'components/common/catch-errors';
import DashboardRoutes from 'components/dashboard/dashboard-routes';
import React, { useEffect, useState } from 'react';
import SlidingColumn from 'components/common/sliding-column';
import SlidingColumns from 'components/common/sliding-columns';
import TimeClock from 'components/dashboard/time-clock';
import { DEFAULT_DASHBOARDS } from 'components/common/constants';
import { Route, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { numPanels } from 'components/common/toolbox';
import { useDispatch, useSelector } from 'react-redux';

export default function Dashboard(props) {
	
	const seg = 4;
	const match = useRouteMatch();
	const history = useHistory();
	const dispatch = useDispatch();
	const routeLocation = useLocation();

    const user = useSelector((store) => store.users.user);
    const admin = useSelector(store => store.admin);

	const [columnState, setColumnState] = useState(); // set by SlidingColumns
	const [panel, setPanel] = useState((admin.dashboard) ? admin.dashboard : 0);
	const [timer, setTimer] = useState(null);

	const pathname = routeLocation.pathname;
	const panelBP = {
		panels3: [99, 99, 99],
		panels2: [99, 99, 99],
		panels1: [99, 99, 99],
	}

	const dashboards = (user.contact?.dashboards) ? user.contact?.dashboards : DEFAULT_DASHBOARDS;
	const routeSegments = pathname.split('/').length;

	/* Effects --------------------------*/

	// Automatically advance to appropriate dashboard
	useEffect(() => {
		if (routeSegments == seg) {
			if (timer) clearTimeout(timer);
			setTimer(
				setTimeout(() => {
					dashboardPanels();
				}, 300)
			);
		}
	}, [pathname, user]);

	useEffect(() => {
		dashboardPanels();
	}, [panel]);

	const dashboardPanels = () => {
		let location = toolbox.modifyPath(pathname, seg, dashboards.slice(panel).join("/"), seg+2 - panel);
		history.replace({ pathname: location });
	}
	const handleTab = (panel) => {
			dispatch(actions_admin.selectDashboard(panel));
			setPanel(panel);
	}
	 
    return (

		<SlidingColumns breakpoints={panelBP} setColumnState={setColumnState} >

			<Route path={match.path + "/:dashboard1"} render={(route_props) => (
				<SlidingColumn index={ 1 } columnState={ columnState } { ...route_props }>
					<CatchErrors className={'m-t-lg'}>
						<TimeClock />
						{ !numPanels(3) &&
							<center>
								<div className="btn-group mb-3">
									<button className={ `btn btn-${(panel == 0) ? 'primary' : 'white'}` } type="button" onClick={ () => handleTab(0) }>Dashboard 1</button>
									<button className={ `btn btn-${(panel == 1) ? 'primary' : 'white'}` } type="button" onClick={ () => handleTab(1) }>Dashboard 2</button>
									{ numPanels(1) &&
										<button className={ `btn btn-${(panel == 2) ? 'primary' : 'white'}` } type="button" onClick={ () => handleTab(2) }>Dashboard 3</button>
									}
								</div>
							</center>
						}
					</CatchErrors>

					<CatchErrors className={'m-t-lg'}>
						<DashboardRoutes { ...route_props } index={ 0 } />
					</CatchErrors>
				</SlidingColumn>
			)} />

			{/* <Route path={match.path + "/:dashboard1/:dashboard2"} render={(route_props) => (
				<SlidingColumn index={ 2 } columnState={ columnState } shift='mt-0'>
					<div className="row">
						<div className="col-12"><h1 className="heading-service-request">Dashboard #2</h1></div>
					</div>
					<CatchErrors className={'m-t-lg'}>
						<DashboardRoutes { ...route_props } index={ 1 } />
					</CatchErrors>
				</SlidingColumn>
			)} />

			<Route path={match.path + "/:dashboard1/:dashboard2/:dashboard3"} render={(route_props) => (
				<SlidingColumn index={ 3 } columnState={ columnState } shift='mt-0'>
					<div className="row">
						<div className="col-12"><h1 className="heading-work-order">Dashboard #3</h1></div>
					</div>
					<CatchErrors className={'m-t-lg'}>
						<DashboardRoutes { ...route_props } index={ 2 } />
					</CatchErrors>
				</SlidingColumn>
			)} /> */}

			<div id="column2-rel" className="col-lg-6 col-xl-4"></div>

		</SlidingColumns>
	);
};
